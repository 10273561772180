export default {
  appEnv: 'production',
  apiURL: 'https://getfloorplan.com/api/',
  apiBackofficeURL: 'https://backend.estate.hart-digital.com',
  sentry: {
    environment: 'production',
    dsn: 'https://3b5c24a00c1562268831d6d243cb2c7b@us.sentry.io/4506692844453888',
  },
  domains: {
    'getfloorplan.com': 'en',
    'hart-estate.ru': 'ru',
  },
};
