import { createRouter, createWebHistory } from 'vue-router';

import { landingRoutes } from '@/router/en/landingRoutes';
import { widgetRoutes, emptyWidgetRoutes } from '@/router/en/widgetRoutes';
import { publicPlanRoutes } from '@/router/en/publicPlanRouter';
import { termsAndConditionsRoutes } from '@/router/en/termsAndConditions';
import { privicyPolicyRoutes } from '@/router/en/privicyPolicy';
import { paymentRoutes } from '@/router/en/paymentRoutes';
import { forPersonalUsersRoutes } from '@/router/en/forPersonalUsers';
import { forCompaniesRoutes } from '@/router/en/forCompanies';
import { newsRoutes } from '@/router/en/news';
import { noveltyRoutes } from '@/router/en/novelty';

const routes = [
  ...landingRoutes,
  ...widgetRoutes,
  ...emptyWidgetRoutes,
  ...publicPlanRoutes,
  ...termsAndConditionsRoutes,
  ...privicyPolicyRoutes,
  ...paymentRoutes,
  ...forPersonalUsersRoutes,
  ...forCompaniesRoutes,
  ...newsRoutes,
  ...noveltyRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export { router, routes };
