import { createApp } from 'vue';
import App from '@/App.vue';
import storeEn from '@/store/en';
import { router as routerEn } from '@/router/en';
import storeRu from '@/store/ru';
import { router as routerRu } from '@/router/ru';
import * as Sentry from '@sentry/vue';
import config from '@/config';

import '@/assets/sass/public-plans/app.sass';
import '@/assets/js/marketing-scripts';

function loadModule(lang) {
  if (lang === 'en') {
    import('@/assets/sass/en/app.sass');
  } else {
    import('@/assets/sass/ru/app.sass');
  }
}

const app = createApp(App);

const defaultLocale = {
  router: routerEn,
  store: storeEn,
};

const locales = {
  en: {
    ...defaultLocale,
  },
  ru: {
    router: routerRu,
    store: storeRu,
  },
};

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0.005,
  replaysOnErrorSampleRate: 1.0,
  sourcemaps: true,
});

const lang = config.domains[Object.keys(config.domains ?? {}).find((k) => window.location.origin.includes(k))];
const currentLocale = locales[lang] ?? defaultLocale;

loadModule(lang);

app.use(currentLocale.store).use(currentLocale.router).mount('#app');
